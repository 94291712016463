const host = `https://api.secesta.eu`;
//const host =  `http://localhost:8080`;
const config = {
    baseUrl:  `${host}`,
    apis: {
        baseUrl: `${host}/api/`,

        register: `${host}/api/auth/signin`,
        login: `${host}/api/auth`,
        refresh: `${host}/api/auth/refreshToken`,
        resetPassword: `${host}/api/auth/resetPassword`,
        
        home: `${host}/api/dashboard`,

        nodes: `${host}/api/nodes/`,
        
        eruption: {
           listLogs: `${host}/api/eruption/listLogs/`,
           downloadLog:   `${host}/api/eruption/downloadLog/`,
        },

        insertNode: `${host}/api/nodes/insertNode`,
        
        areas: `${host}/api/areas/`,
        areasColor: `${host}/api/areas/color`,

        users:{
            get: `${host}/api/users/`,
            new: `${host}/api/users/`,
            edit: `${host}/api/users/`, 
            getUser: `${host}/api/users/`, 
            delete: `${host}/api/users/`,
            enableUser: `${host}/api/users/`,
            getProfile: `${host}/api/users/`,
            updateProfile: `${host}/api/users/`,
            updatePassword: `${host}/api/users/`,

        },   
        monitoring:{
            start: `${host}/api/eruption/start`,
            stop: `${host}/api/eruption/end`,
        }    

    },

    role:{
        "superadmin": "1",
        "admin": "2",
        "operator": "3",
    }
    
}
export default config;