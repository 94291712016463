import React, { useEffect } from "react";
import { useState } from 'react';
import { BrowserRouter as Route, useNavigate } from "react-router-dom";
//import materialUI 
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { Card, Link, Container, Typography, stepButtonClasses } from '@mui/material';
import { display, maxHeight, maxWidth, minHeight, minWidth } from "@mui/system";
import { Stack, TextField, Select, ChangeEvent, FormControl, MenuItem, IconButton,  InputLabel, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//import helper-> per laggiunta delle immagini
import importer from '../helpers/importer';
import theme from '../helpers/theme';
import config from '../config';
import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQuery from '@mui/material/useMediaQuery';
import { CenterFocusStrong, ExitToApp } from "@mui/icons-material";


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import PasswordStrenghtIndicator from "../components/PasswordStrenghtIndicator";
import { light } from "@mui/material/styles/createPalette";




const axios = require('axios').default;

const isNumberRegx = /^\d+$/; 
const regExpWeak = /^[a-z]+$/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const chars = /[A-Z]{3}([0-9]{1,4})?|[A-Z]{1,3}/;
let allAreas;

function Signin(){

const [filteredAreas, setFilteredAreas] = useState([]);
const [selectedArea_1, setSelectedArea_1] = useState("");
const [filteredAreas_2, setFilteredAreas_2] = useState([]);
const [selectedArea_2, setSelectedArea_2] = useState("");
const [filteredAreas_3, setFilteredAreas_3] = useState([]);
const [selectedArea_3, setSelectedArea_3] = useState("");
const [filteredAreas_4, setFilteredAreas_4] = useState([]);
const [selectedArea_4, setSelectedArea_4] = useState("");
const [filteredAreas_5, setFilteredAreas_5] = useState([]);

const [areas, setAreas] = useState('');
const [areas_2, setAreas_2] = useState('');
const [areas_3, setAreas_3] = useState('');
const [areas_4, setAreas_4] = useState('');
const [areas_5, setAreas_5] = useState('');

// style
    const containerStyle = {
        height: '100%',
        backgroundColor: "#E0E1E1"
    
    }

    const formContainer = {
        width:'75%', 
        padding:'5px'
        
    }

    const sectionStyle1 = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor:'#fff',
        padding:'20px',
        position:'relative',

    }
    const sectionStyle4 = {
        
    }
    const sectionStyle2 = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding:'20px',

    }
    const textFieldStyle = {
        backgroundColor:'#fff',
        marginBottom:'20px',
       
    }
    const buttonStyle = {
        backgroundColor: theme.colors.primary, 
        color:"#fff",
    }

    const copyright = {
        // position: 'absolute',
        // top:'100%',
        // transform:'translateY(-100%)',
        color: theme.colors.primary,
        paddingBottom:'5px',
    }
    const copyrightText = {
        all: 'unset',
        cursor: 'pointer',
      
    }
    // media query
    
    const isDesktop = useMediaQuery('(min-width: 660px)');

    const sectionStyle3 = {
        display: 'flex',
        justifyContent:'center',
        flexDirection: 'column ',
    
    }

    const TextFieldStyle = {
        borderRadius: '10px',
        backgroundColor:"#fff",
      };


    const getAreas = async (levelArea) => {
        try {
          console.log("levelArea", levelArea);
          const response = await axios.get(config.apis.areas);
          allAreas = response.data;
          console.log("allAreas - response", allAreas);
    
          const filteredAreas = allAreas[0].areas.areasChildren.filter(
            (item) => item.areaParent.type === 1 ||  item.areaParent.type === 2
          );
          console.log("area filtered now", filteredAreas);
    
          setFilteredAreas(filteredAreas); 
        } catch (error) {
          console.error("Errore durante il recupero delle aree:", error);
        }
    };

    const handleAreaChange = (event) => {
        const selectedAreaId = event.target.value;
        setAreas(event.target.value);
        setSelectedArea_1(selectedAreaId);
    
        // Ottenere i children dell'area selezionata
        const selectedAreaData = filteredAreas.find(
          (area) => area.areaParent.id === selectedAreaId
        );
    
        if (selectedAreaData) {
          setFilteredAreas_2(selectedAreaData.areasChildren);
          setFilteredAreas_3([]);
          setFilteredAreas_4([]);
        } else {
          setFilteredAreas_2([]);
        }
        console.log("filteredAreas_2",filteredAreas_2);
    };

    const handleAreaChange_2 = (event) => {
        const selectedAreaId_2 = event.target.value;
        setAreas_2(event.target.value);
        setSelectedArea_2(selectedAreaId_2);
    
      
        const selectedAreaData_2 = filteredAreas_2.find(
          (area) => area.areaParent.id === selectedAreaId_2
        );
    
        if (selectedAreaData_2) {
          setFilteredAreas_3(selectedAreaData_2.areasChildren);
          setFilteredAreas_4([]);
        } else {
          setFilteredAreas_3([]);
        }
    };

    const handleAreaChange_3 = (event) => {
        const selectedAreaId_3 = event.target.value;
        setAreas_3(event.target.value);
        setSelectedArea_3(selectedAreaId_3);
    
      
        const selectedAreaData_3 = filteredAreas_3.find(
          (area) => area.areaParent.id === selectedAreaId_3
        );
    
        if (selectedAreaData_3) {
          setFilteredAreas_4(selectedAreaData_3.areasChildren);
        } else {
          setFilteredAreas_4([]);
        }
    }

    const handleAreaChange_4 = (event) => {
        const selectedAreaId_4 = event.target.value;
        setAreas_4(event.target.value);
        setSelectedArea_4(selectedAreaId_4);
    
       
        const selectedAreaData_4 = filteredAreas_3.find(
          (area) => area.areaParent.id === selectedAreaId_4
        );
    
        if (selectedAreaData_4) {
          setFilteredAreas_5(selectedAreaData_4.areasChildren);
        } else {
          setFilteredAreas_5([]);
        }
       
    }

    const handleAreaChange_5 = (event) => {
        const selectedAreaId_5 = event.target.value;
        setAreas_5(event.target.value);
        //setSelectedArea_5(selectedAreaId_5);
    
    }
    
    useEffect(() => {
        getAreas(1);
    }, []);

   


    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [password, setPassword] = useState("aaa");
   
      
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null, 
        bChar: null,
    });

    const handleChangeArea = (event) => {
        setAreas(event.target.value);
      };
    // controllo validità della password 
    const onChangePassword = pw =>{
        setPassword(pw);
        setPasswordValidity({ 
            minChar: pw.length >= 8 ? true : false, 
            number: /[0-9]/.test(pw) ? true : false, //se c'è un numero è true
            specialChar: specialCharacterRegx.test(pw)? true : false, 
            chars: chars.test(pw)? true : false,
        });

    }

   

    
    // alert 
    const emailAlert = withReactContent(Swal);
    const pwAlert = withReactContent(Swal);
    
    // gestione registrazione
    let handleSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData(e.target);
        var payload = {};
        formData.forEach(function(value, key){
            payload[key] = value;
        });
        console.log("payload", payload); 
        // controllo validità password 
        if((password.match(regExpWeak) || password.match(isNumberRegx) || !password.match(specialCharacterRegx) || password.length <= 8)){
            e.preventDefault();
            
            
            // alert formato password non valido
            pwAlert.fire({
                icon: 'error', 
                text: 'Il formato della password non è valido!',
                confirmButtonColor: '#CE3B33',
            });

            
        }else{
            axios.post(config.apis.register, {params: {payload}}).then(
                (response) => {
                    console.log(response);
    
                    navigate('/', {replace: true});
    
                   
                }
            ).catch((error) => {
                switch(error.response.status){
                  
                    case 400 : {
                        emailAlert.fire({
                            icon: 'warning',    
                            text:'Alcuni campi sono vuoti!',
                            confirmButtonColor: '#CE3B33',
                        });
                        break;
                    };
                    case 406 : {
                        emailAlert.fire({
                            icon: 'warning',    
                            text: 'Email già esistente!',
                            confirmButtonColor: '#CE3B33',   
                        })
                        break;
                    };
    
                    default:{
                        emailAlert.fire({
                            icon: 'error', 
                            text: 'Errore del server',
                            confirmButtonColor: '#CE3B33',                       
                      })
                    break;
                }
    
                }
            })
            const userAlert = withReactContent(Swal);
            userAlert.fire({
                icon: 'success',
                text: "Utente registrato con successo!",
                confirmButtonColor: '#CE3B33',
                willClose: () => {
                    navigate('/', {replace: true});
                }

            });

        }
        
    }  
 
    console.log("filteredAreas", filteredAreas);
    console.log("allAreas", allAreas);


   
    return(
        <>
        <Grid container style={containerStyle}>
            {isDesktop && (
                <Grid item style={sectionStyle1} xs={6}>
                    
                    <img alt="register" src ={ importer.img.require('logotipo_orizzontale.png') }/> 

                    <Box style={{marginRight:'20px',display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center",position:"absolute",bottom:"0"}}>

                        <img style={{width: '50%' }} alt="register" src ={ importer.img.require('gruppo_loghi_nero.png') }/> 

                        <h3 style={{color:"#808080", fontSize:"14px",  textAlign:"center"}}>Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, ee la Commisisone non può essere ritenuta responsabile per l'uso che può essere ritenuta respoonsabile per l’uso che può essere fatto delle informazioni ivi contenute. </h3>
                        <h3 style={{color:"#808080", fontSize:"14px", textAlign:"center"}}>N. Progetto 08CT6202000208 – CUP G69J18001010007</h3>
                    
                    </Box>

                </Grid>
            )}

            <Grid item style={sectionStyle2} xs={isDesktop?6: 12}>
            
            {!isDesktop && (
                <img alt="register" style={{width:'75%'}} src ={ importer.img.require('logotipo_orizzontale.png') }/> 
            )}

                <Box  style={formContainer}>
                {!isDesktop && (
                    <Box>
                        <h2>Registrati</h2>
                        <h3>Inserisci i tuoi dati</h3>
                    </Box>
                )}

                {isDesktop && (
                    <Box>
                        <h1>Registrati</h1>
                        <h3>Inserisci i tuoi dati</h3>
                    </Box>
                )}
                
                    

                    <form style={{display:"block"}} onSubmit={handleSubmit}>
                        <Stack spacing={1}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>

                            <TextField style = {TextFieldStyle}
                            fullWidth
                            name="name"
                            label="Nome"
                            required
                            />

                            <TextField style = {TextFieldStyle}
                            fullWidth
                            name="surname"
                            label="Cognome"
                            required
                        
                            />
                        </Stack>

                        <TextField style = {TextFieldStyle}
                            fullWidth
                            name="email"
                            autoComplete="email"
                            type="email"
                            label="Email"
                            required
                        
                            
                        />

                        <TextField style = {TextFieldStyle}
                            fullWidth
                            name="phone"
                            autoComplete="numero"
                            label="Telefono"
                            required
                            
                            
                        />

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Area d'interesse</InputLabel>
                            <Select style = {TextFieldStyle}
                                name="interestArea"
                                labelId="interestArea-label"
                                id="interestArea"
                                value={areas}
                                label="Area d'interesse"
                                onChange={handleAreaChange}
                                required
                            >
                            {filteredAreas.map((area) => (
                                <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                    {area.areaParent.name}
                                </MenuItem>
                            ))}    

                            {/* <MenuItem value={1}>Sicilia</MenuItem>
                            <MenuItem value={2}>Etna</MenuItem>
                            <MenuItem value={3}>Catania</MenuItem> */}
                       
                            </Select>
                        </FormControl>
                            
                        {selectedArea_1 && (
                        <FormControl fullWidth>
                           <InputLabel id="demo-simple-select-label_2">Sotto area</InputLabel>
                                <Select style = {TextFieldStyle}
                                name="interestArea2"
                                labelId="interestArea2-label"
                                id="interestArea2"
                                value={areas_2}
                                label="Area d'interesse"
                                onChange={handleAreaChange_2}
                                
                                >
                                {filteredAreas_2.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                                ))}    
                                </Select>
                        </FormControl>
                        )}
                            
                        {selectedArea_2 && (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label_3">Sotto area</InputLabel>
                            <Select style = {TextFieldStyle}
                                name="interestArea3"
                                labelId="interestArea3-label"
                                id="interestArea3"
                                value={areas_3}
                                label="Area d'interesse"
                                onChange={handleAreaChange_3}
                               
                            >
                                {filteredAreas_3.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                                ))}    
                            </Select>
                            
                        </FormControl>
                        )}

                        {selectedArea_3 && (
                        <FormControl fullWidth>
                           <InputLabel id="demo-simple-select-label_4">Sotto area</InputLabel>
                            <Select style = {TextFieldStyle}
                                name="interestArea4"
                                labelId="interestArea4-label"
                                id="interestArea4"
                                value={areas_4}
                                label="Area d'interesse"
                                onChange={handleAreaChange_4}
                                
                            >
                                {filteredAreas_4.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                                ))}    
                            </Select>
                        </FormControl>    
                        )}

                            
                           
                            {/* {selectedArea_4 && (
                            
                            <Select style = {TextFieldStyle}
                                name="interestArea5"
                                labelId="interestArea5-label"
                                id="interestArea5"
                                value={areas_5}
                                label="Area d'interesse"
                                onChange={handleAreaChange_5}
                                required
                            >
                                {filteredAreas_5.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                                ))}    
                            </Select>
                            )} */}

                        

                        <TextField style = {TextFieldStyle}
                            fullWidth
                            name = "password"
                            autoComplete="password attuale"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            
                            onChange={ e => { onChangePassword(e.target.value) } }
                            onFocus={() => setPasswordFocused(true)}
                            required
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        
                        <div style={{ height:"10px", display: password? 'flex' : 'block', justifyContent:'space-between',alignItems:'center', margin:'10px 0' }}>
                                 
                            <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                            backgroundColor: 
                                ((password.match(regExpWeak) || password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#fb0400" : "lightgray" }}/> 
                            <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                            backgroundColor: 
                                (password.length >= 3 && password.match(regExpWeak) && (password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#f5f491": "lightgray" }}/> 
                            <span style= {{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                            backgroundColor:
                                (password.length >= 3 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) || password.match(chars) )) ?  "#fbcb45": "lightgray" }}/> 
                            <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                             backgroundColor:
                                (password.length >= 8 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) && password.match(chars) )) ?  "#00eb1e": "lightgray" }}/>  
                        
                        </div>

                        { passwordFocused && (<PasswordStrenghtIndicator validity={passwordValidity}/>)}
                        

                        <LoadingButton fullWidth size="large" type="submit" variant="contained"  style={{backgroundColor: "#ce3b33" , color:"#fff"}}>
                            Registrati
                        </LoadingButton>
                        </Stack>
                    </form>


                    <Typography variant="body2"  align="center" style={{ margin:"10px"}}>
                        Hai già un account? {''}
                            <Link  style ={{'color':theme.colors.primary, "cursor": "pointer"}}  onClick={() => {navigate('/login', {replace: true})  } }  >
                                Login
                            </Link>
                    </Typography>

                </Box>
                {isDesktop && (
                    <Box style={copyright}>
                        <a style={copyrightText} href='https://www.pmf-research.eu/' >&copy; PMF RESEARCH</a>
                    </Box>)
                }

            </Grid>



            {!isDesktop && (
                        <Box style={{ left: "0px", bottom: "0px"}}>

                            <div style={{display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center", marginBottom:'5px'}}>
                                <a style={{ all: 'unset', cursor: 'pointer',}} href='https://www.pmf-research.eu/' >&copy; PMF RESEARCH</a>                        

                            </div>
                      

                            <section style={{ padding:'10px 5px 10px 5px', backgroundColor:'#404040',display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center"}}>
                                    
                                <img style={{width:'90%'}} alt="register" src ={ importer.img.require('gruppo_loghi_bianco.png') }/> 

                                <h3 style={{marginTop:'10px', color:"#808080", fontSize:"8px",  textAlign:"justify"}}>Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, ee la Commisisone non può essere ritenuta responsabile per l'uso che può essere ritenuta respoonsabile per l’uso che può essere fatto delle informazioni ivi contenute. </h3>
                                <h3 style={{marginTop:'10px', color:"#808080", fontSize:"6px", textAlign:"center"}}>N. Progetto 08CT6202000208 – CUP G69J18001010007</h3>
                                

                            </section>
                        </Box>
                    )}
        </Grid>


        

        
    </>
    );


}

export default Signin;




