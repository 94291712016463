import { useState, useEffect } from 'react';
// import { BrowserRouter as Route, useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import React, { PureComponent } from 'react';
import {Button, Stack, TextField} from '@mui/material';
import axios from 'axios';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import config from '../config';
function PowerOn() {
      
  const [monitoringStatus, setMonitoringStatus] = useState('Idle');
  const token = localStorage.getItem('token');

  const startMonitoring = async () => {
    try {
      const response = await axios.get(
        config.apis.monitoring.start,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMonitoringStatus('Monitoring Started');
      console.log('Start Monitoring Response:', response.data);
    } catch (error) {
      console.error('Error starting monitoring:', error);
      setMonitoringStatus('Error Starting Monitoring');
    }
  };

  const stopMonitoring = async () => {
    try {
      const response = await axios.get(
        config.apis.monitoring.stop,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMonitoringStatus('Monitoring Stopped');
      console.log('Stop Monitoring Response:', response.data);
    } catch (error) {
      console.error('Error stopping monitoring:', error);
      setMonitoringStatus('Error Stopping Monitoring');
    }
  };

  return (
    <Box sx={{ padding: '100px', textAlign: 'center' }}>
      <Typography variant="h4" sx={{ marginBottom: '50px' }}>
        Monitoraggio delle eruzioni
      </Typography>

 

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <Button
          variant="contained"
          color="success"
          onClick={startMonitoring}
        >
          Avvia Monitoraggio Eruzione
        </Button>

        <Button
          variant="contained"
          color="error"
          onClick={stopMonitoring}
        >
          Ferma Monitoraggio Eruzione
        </Button>
      </Box>
    </Box>
  );
}


export default PowerOn;