import React, { useEffect } from "react";
import { useState } from 'react';
import { BrowserRouter as Route, useNavigate } from "react-router-dom";

import useResponsive from '../helpers/useResponsive';

//import materialUI 
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { Stack, TextField, Select, ChangeEvent, FormControl, MenuItem, IconButton,  InputLabel, InputAdornment } from '@mui/material';
import { Card, Link, Container, Typography } from '@mui/material';
import { maxHeight, maxWidth } from "@mui/system";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


//import helper-> per laggiunta delle immagini
import importer from '../helpers/importer';
import theme from '../helpers/theme';
import config from "../config";
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const axios = require('axios').default;

function Login(props){

    const login = props.login;



    const containerStyle = {
        height: "100%",
      
    
    }

    const formContainer = {
        width:'75%',
    }
    
    const sectionStyle1 = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor:'#fff',
        padding:'20px',
        position:'relative',
        

    }
    const sectionStyle2 = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding:'20px',

    }
    const textFieldStyle = {
        backgroundColor:'#fff',
        marginBottom:'20px',
       
    }
    const buttonStyle = {
        backgroundColor: theme.colors.primary, 
        color:"#fff",
    }

    const copyright = {
        position: 'absolute',
        top:'100%',
        transform:'translateY(-100%)',
        color: theme.colors.primary,
        paddingBottom:'5px',
    }
    const copyrightText = {
        all: 'unset',
        cursor: 'pointer',

    }
    const TextFieldStyle = {
        borderRadius: '10px',
        backgroundColor:"#fff",
      };
    
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    
    
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
      };

    const userAlert = withReactContent(Swal);  



    let handleSubmit = (e) => {
        e.preventDefault();


        let formData = new FormData(e.target);
        var payload = {};
        formData.forEach(function(value, key){
            payload[key] = value;
        });
        
        axios.post(config.apis.login, {params: {payload}}).then(
            (response) => {
                login(response);
            }
        ).catch( (error) => {
            switch(error.response.status){

                case 400:{
                    userAlert.fire({
                        icon: 'error',
                        text:'Credenziali non corrette!',
                        confirmButtonColor: '#CE3B33',
                    });
                    break;
                }
                case 401:{
                    userAlert.fire({
                        icon: 'warning',
                        text:'Non sei stato ancora abilitato',
                        confirmButtonColor: '#CE3B33',
                    });
                    break;
                }
                case 406:{
                    userAlert.fire({
                            icon: 'error',
                            text:'Gli operatori non possono effettuare il login',
                            confirmButtonColor: '#CE3B33',
                        });
                    break;
                }

            default:{
              
            }
        
        }}
        );    

    }
    
    
    const isDesktop = useMediaQuery('(min-width: 660px)');
 
    return(
       
        <>

            <Grid  container style={containerStyle}>

            {isDesktop && (
                <Grid item style={sectionStyle1} xs={6}>
                    
                    <img alt="register" src ={ importer.img.require('logotipo_orizzontale.png') }/> 

                    <Box style={{marginRight:'20px',display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center",position:"absolute",bottom:"0"}}>

                        <img style={{width: '50%' }} alt="register" src ={ importer.img.require('gruppo_loghi_nero.png') }/> 

                        <h3 style={{color:"#808080", fontSize:"14px",  textAlign:"center"}}>Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, e la Commissione non può essere ritenuta responsabile per l'uso che può essere ritenuta responsabile per l’uso che può essere fatto delle informazioni ivi contenute.</h3>
                        <h3 style={{color:"#808080", fontSize:"14px", textAlign:"center"}}>N. Progetto 08CT6202000208 – CUP G69J18001010007</h3>
                    </Box>
                
                </Grid>

            )}
                         
                    
                    <Grid item style={sectionStyle2} xs={isDesktop?6: 12}>
                    {!isDesktop && (
                         <img alt="register" style={{width:'75%'}} src ={ importer.img.require('logotipo_orizzontale.png') }/> 
                    )}

                        <Box style={formContainer}>
                        {!isDesktop && (
                            <Box>
                                <h2>ACCEDI</h2>
                                <h3>Inserisci le tue credenziali</h3>
                            </Box>
                        )}


                        {isDesktop && (
                            <Box>
                                <h1>ACCEDI</h1>
                                <h3>Inserisci le tue credenziali</h3>
                            </Box>
                        )}

                            <form style={{display:"block"}} onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                    <TextField style = {TextFieldStyle}
                                        fullWidth
                                        name= "email"
                                        autoComplete="username"
                                        type="email"
                                        label="Email address"
                                        required
                                    />

                                    <TextField style = {TextFieldStyle}
                                        fullWidth
                                        name= "password"
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        label="Password"
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword} edge="end">
                                                {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        required
                                        
                                    />
                                    </Stack>

                                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                

                                    {/* <Link style={{color:"#ce3b33"}} variant="subtitle2" to="#" underline="hover">
                                        Forgot password?
                                    </Link> */}
                                    </Stack>

                                    <LoadingButton fullWidth size="large" type="submit" variant="contained" style={{backgroundColor: "#ce3b33" , color:"#fff"}}>
                                        Login 
                                    </LoadingButton>
                                    

                            </form>
                            
                            <Typography variant="body2"  align="center" style={{color:"text.secondary" , margin:"10px"}}>
                                Non hai un account? {''}
                                <Link  style ={{'color':theme.colors.primary,  cursor: 'pointer'}}  onClick={() => {navigate('/signin', {replace: true})}} >
                                Registrati
                                </Link>
                            </Typography>
                            
                            <Typography variant="body2"  align="center" style={{color:"text.secondary" , margin:"10px"}}>
                                <Link  style ={{'color':theme.colors.primary, cursor: 'pointer'}}  onClick={() => {navigate('/forgot_password', {replace: true})}} >
                                Password dimenticata? 
                                </Link>
                            </Typography>

                        </Box>

                        
                        {isDesktop && (
                            <Box style={copyright}>
                                <Link style={copyrightText} href='https://www.pmf-research.eu/' >&copy; PMF RESEARCH</Link>
                            </Box>)
                        }

                    </Grid>

                    
                       
                  
                   

                    {!isDesktop && (
                     <Box style={{ left: "0px", bottom: "0px"}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center", marginBottom:'5px'}}>
                            <a style={{ all: 'unset', cursor: 'pointer',}} href='https://www.pmf-research.eu/' >&copy; PMF RESEARCH</a>                        

                        </div>
                      
                        <section style={{ padding:'10px 5px 10px 5px', backgroundColor:'#404040',display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center"}}>
                             
                            <img style={{width:'90%'}} alt="register" src ={ importer.img.require('gruppo_loghi_bianco.png') }/> 

                            <h3 style={{marginTop:'10px', color:"#808080", fontSize:"8px",  textAlign:"justify"}}>Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, ee la Commisisone non può essere ritenuta responsabile per l'uso che può essere ritenuta respoonsabile per l’uso che può essere fatto delle informazioni ivi contenute. </h3>
                            <h3 style={{marginTop:'10px', color:"#808080", fontSize:"6px", textAlign:"center"}}>N. Progetto 08CT6202000208 – CUP G69J18001010007</h3>
                          

                        </section>
                 </Box>
                )}
                    
                    
                </Grid>

                

          
                        
                    
        </>
    );


}

export default Login;

